<template>
  <div>
    <div class="duration-300">
      <datatable
        :url="cashbacks.url"
        :ajax="true"
        :ajaxPagination="true"
        :columns="cashbacks.columns"
        :fillable="false"
        :loading="cashbacks.loading"
        :limit="5"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cashbacks: this.$options.resource([], {
        url: `${this.$baseurl}/personal/users/transaction/cashbacks`,
        columns: [
          {
            name: "reference",
            th: "Reference",
          },
          {
            name: "commission",
            th: "Commission",
            render: (cashback) =>
              cashback?.type === "percentage"
                ? `${cashback?.commission}%`
                : cashback?.commission,
          },
          {
            name: "amount",
            th: "Amount",
            render: (cashback) =>
              this.$options.filters.formatAmount(cashback?.amount),
          },
          {
            name: "cashback",
            th: "Cashback",
            render: (cashback) =>
              this.$options.filters.formatAmount(cashback?.cashback),
          },
          {
            name: "created_at",
            th: "Created At",
            render: (cashback) =>
              this.$moment(cashback?.created_date).format("dddd, MMMM Do YYYY"),
          },
        ],
      }),
    };
  },
  methods: {
    async cashbackTransactions() {
      this.cashbacks.loading = true;
      await this.sendRequest("personal.wallet.transaction.cashbacks", {
        success: (response) => (this.cashbacks.data = response.data.data),
        error: (error) => console.log(error),
      });
      this.cashbacks.loading = false;
    },
  },
};
</script>